.root {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: var(--space-sm);

	button {
		margin-top: var(--space-sm);
	}

	@media (--md) {
		width: 465px;
	}
}

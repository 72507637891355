.root {
	margin-top: 60px;
	padding-block: 30px;
	padding-inline: 20px;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-areas:
		"newsletter"
		"links"
		"utility";
	gap: var(--space-lg);

	@media (--md) {
		margin-top: 120px;
		padding-inline: 60px;
		padding-top: 84px;
		padding-bottom: 94px;
		grid-template-columns: 40% 60%;
		grid-template-areas:
			"newsletter links"
			"utility utility";
		gap: var(--space-sm);
	}

	& .newsletter {
		grid-area: newsletter;
		display: flex;
		flex-direction: column;
		align-items: center;

		@media (--md) {
			width: 60%;
			min-width: 290px;
			align-items: flex-start;
		}

		& .header {
			margin-bottom: var(--space-xs);
		}

		& .subHeader {
			margin-bottom: var(--space-md);
			text-align: center;

			@media (--md) {
				margin-bottom: var(--space-2xs);
				text-align: left;
			}
		}

		& .input {
			margin-bottom: 12px;

			@media (--md) {
				margin-bottom: 8px;
			}
		}

		& .stretch {
			width: 100%;

			@media (--md) {
				width: auto;
			}
		}
	}

	a {
		text-decoration: none;
		color: inherit;
	}

	& .links {
		grid-area: links;
		display: grid;
		grid-template-columns: 1fr;

		@extend %typo-body-2;

		@media (--md) {
			grid-template-columns: 1fr 1fr 1fr;
		}

		& .allLarge {
			margin-bottom: var(--space-sm);
			display: flex;
			flex-direction: column;
			border-bottom: 1px solid var(--primary-dark-blue-005);

			@extend %typo-heading-4-bold;

			& > a {
				margin-bottom: var(--space-sm);
			}

			@media (--md) {
				border: none;
				margin-bottom: 0;
			}
		}

		& .firstLarge {
			display: none;

			@media (--md) {
				display: flex;
				flex-direction: column;
			}

			& > a {
				margin-bottom: var(--space-xs);
			}

			& > a:first-child {
				@extend %typo-heading-4-bold;

				margin-bottom: var(--space-sm);
			}
		}

		@media (hover: hover) {
			a:hover {
				text-decoration: underline;
			}
		}
	}

	& .utility {
		margin-top: var(--space-sm);
		display: grid;
		gap: var(--space-sm);
		grid-area: utility;
		grid-template-columns: 1fr;
		grid-template-areas:
			"language"
			"socials"
			"paymentOptions"
			"terms";

		& .terms {
			width: 100%;
			display: grid;
			align-items: center;
			justify-content: center;
			gap: var(--space-xs) var(--space-md);
			grid-area: terms;
			text-align: center;
			grid-template-areas:
				"legal legal"
				"copyright copyright";
			grid-template-columns: fit-content(100%) fit-content(100%);

			@media (--lg) {
				justify-content: flex-start;
				grid-template-areas: "copyright legal";
				grid-template-columns: fit-content(100%) 1fr;
				gap: 24px;
			}

			& .legal {
				grid-area: legal;
				display: grid;
				grid-auto-flow: column;
				gap: 24px;
				grid-auto-columns: max-content;

				& a {
					text-decoration: none;
					max-width: fit-content;
				}
			}

			& .copyright {
				grid-area: copyright;
			}
		}

		& .language {
			width: 100%;
			grid-area: language;
			display: flex;
			justify-content: center;
			gap: var(--space-md);

			@media (--lg) {
				justify-content: flex-end;
			}
		}

		& .socials {
			width: 100%;
			display: flex;
			justify-content: center;
			gap: 8px;
			grid-area: socials;

			@media (--lg) {
				justify-content: flex-start;
			}
		}

		.paymentOptions {
			width: 100%;
			margin-left: 0;
			padding-block: var(--space-2xs);
			display: flex;
			align-items: center;
			justify-content: center;
			gap: var(--space-xs);
			grid-area: paymentOptions;

			@media (--lg) {
				margin-left: var(--space-md);
				padding-block: 0;
				justify-content: flex-start;
			}

			& .paymentOption {
				height: 15px;
				display: flex;
				align-items: center;

				& .paymentAsset {
					width: auto;
					height: 100%;
				}
			}
		}

		@media (--lg) {
			margin-top: 0;
			display: grid;
			grid-template-columns: repeat(4, auto);
			grid-template-areas: "terms language paymentOptions socials";
			justify-items: center;
			gap: 0;
		}
	}

	& .footerHeader {
		margin-bottom: var(--space-sm);
	}

	& .footerLink {
		margin-bottom: var(--space-xs);
	}
}

.accordionRoot {
	padding: 0;
	display: block;

	@media (--md) {
		display: none;
	}
}
